<template>
    <modal ref="modalVerMasInfo" titulo="Otras mascotas" no-aceptar no-cancelar>
        <div v-for="(data, index) in otros" :key="index" class="row mx-5 mb-4">
            <p class="col-auto f-16 f-300" style="text-transform:capitalize">
                {{ data.mascota }}
            </p>
            <p class="col-auto f-16 f-300 ml-auto">
                {{ data.total }}
            </p>
            <div class="col-12 bg-whitesmoke2 px-0" style="height: 10px;">
                <div class="h-100 bg-green" :style="`width:${porcentaje(data.total)}%`" />
            </div>
        </div>
        <div class="row mx-0 py-2" />
    </modal>
</template>
<script>
import Service from '@/services/estadisticas'

export default {
    data(){
        return {
            otros: [],
            total: 0
        }
    },
    methods: {
        toggle(conjuntos){
            this.getOtrasMascotas(conjuntos);
            this.$refs.modalVerMasInfo.toggle()
        },

        async getOtrasMascotas(conjuntos){
            try {
                const params = {
                    conjuntos: JSON.stringify(conjuntos)
                };
                const {data} = await Service.getOtrasMascotas(params);
                this.total = data.total;
                setTimeout(() => {
                    this.otros = data.otros;
                },200);
            } catch(e){
                this.console.error_catch(e);
            }
        },

        porcentaje(total){
            let porcentaje = (parseInt(total) / parseInt(this.total)) * 100;
            return Math.round(porcentaje);
        },
    }
}
</script>